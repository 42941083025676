@import '../../../../scss/theme-bootstrap';

@if $cr20 == true {
  .site-email-signup {
    @include swap_direction(margin, 0);
    height: $site-banner-height;
    min-width: 45px;
    vertical-align: top;
    font-family: $ano-black_regular-font;
    font-size: 12px;
    @include breakpoint($bp--largest-up) {
      display: block;
      background-color: $color--black;
    }
    &.active {
      background: $color--white;
      color: $color--black;
      @include breakpoint($bp--largest-up) {
        background-color: $color--white;
        color: $color--black;
      }
    }
    &__link {
      @include unset-underline-links();
      line-height: $site-banner-height;
      color: $color--white;
      text-align: center;
      text-transform: none;
      display: inline-block;
      margin: 0 7px;
      .gnav-util-trigger:checked ~ & {
        background-color: $color--white;
      }
      &-header {
        text-transform: none;
        text-decoration: underline;
        font-size: 12px;
        font-family: $helvetica-font;
        @include breakpoint($bp--largest-up) {
          color: $color--white;
        }
        .active & {
          height: 32px;
          display: block;
          @include breakpoint($bp--largest-up) {
            color: $color--black;
          }
        }
        .gnav-util-trigger:checked ~ .site-email-signup__link & {
          @include breakpoint($bp--largest-up) {
            color: $color--black;
          }
        }
      }
      &-icon {
        color: $color--white;
        .active & {
          @include breakpoint($bp--largest-up) {
            color: $color--black;
          }
        }
      }
    }
    &__link-label {
      cursor: pointer;
    }
    &__contents {
      @include body-mono();
      background: $color--white;
      color: $color--black;
      visibility: hidden;
      opacity: 0;
      width: 322px;
      position: absolute;
      #{$rdirection}: 0;
      text-align: #{$ldirection};
      top: auto;
      z-index: calc(#{$gnav-fixed-z-index} + 1);
      .gnav-util-trigger:checked ~ &,
      .active & {
        opacity: 1;
        visibility: visible;
        max-height: 430px;
        overflow-y: auto;
      }
      a:not(.btn) {
        @include set-link-colors();
      }
      &-header {
        @include clearfix();
        @include swap_direction(padding, 15px 15px 5px 15px);
        line-height: 30px;
      }
      &-close {
        @include unset-underline-links();
        float: #{$rdirection};
        font-size: 23px;
        i {
          vertical-align: top;
        }
      }
      &-title {
        @include h5();
        @include swap_direction(margin, 0);
        line-height: 1;
        a {
          @include unset-underline-links();
          @if $fonts_update == true {
            @include h4();
          }
        }
      }
      &-form {
        @include swap_direction(padding, 0 15px 15px 15px);
        a:not(.btn) {
          @include set-underline-links();
          text-transform: none;
        }
        p {
          @include swap_direction(margin, 0 0 10px 0);
        }
        .intro {
          width: 96%;
        }
        .error {
          display: block;
        }
        .form-item {
          @include swap_direction(margin, 0 0 15px 0);
        }
        .form-type-checkbox,
        .form-type-label {
          margin-bottom: 0;
          display: none;
        }
        .picker-checkbox {
          @include swap_direction(margin, 0);
          display: block;
        }
        .picker-handle {
          float: #{$ldirection};
        }
        .picker-label {
          text-transform: none;
          width: 96%;
          p {
            @include swap_direction(margin, 0 0 15px 0);
          }
        }
      }
      &-submit {
        @include btn;
        @include btn--full;
      }
      &-thank-you {
        &__title {
          @include swap_direction(margin, 0 0 15px 0);
        }
        &__copy {
          line-height: 1.5;
        }
      }
    }
  }
}
